exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-chapter-page-js": () => import("./../../../src/templates/chapter-page.js" /* webpackChunkName: "component---src-templates-chapter-page-js" */),
  "component---src-templates-general-js": () => import("./../../../src/templates/general.js" /* webpackChunkName: "component---src-templates-general-js" */),
  "component---src-templates-item-page-js": () => import("./../../../src/templates/item-page.js" /* webpackChunkName: "component---src-templates-item-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-release-note-js": () => import("./../../../src/templates/release-note.js" /* webpackChunkName: "component---src-templates-release-note-js" */),
  "component---src-templates-rolling-chapter-page-js": () => import("./../../../src/templates/rolling-chapter-page.js" /* webpackChunkName: "component---src-templates-rolling-chapter-page-js" */),
  "component---src-templates-rolling-title-page-js": () => import("./../../../src/templates/rolling-title-page.js" /* webpackChunkName: "component---src-templates-rolling-title-page-js" */),
  "component---src-templates-title-page-js": () => import("./../../../src/templates/title-page.js" /* webpackChunkName: "component---src-templates-title-page-js" */)
}

